import { BASE_URL } from '@/config';
import axios from 'axios';
import { getExpiredTimeFromToken } from '@/utils/token';
import { getRefreshedToken } from '@/apis/auth';

export const baseHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=UTF-8',
};

export const authHeader = (token: string | null | undefined) => {
  if (token !== null) {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=UTF-8',
      Authorization: 'Bearer ' + token, // + accessToken or refreshToken,
    };
  } else {
    return baseHeaders;
  }
};

const axiosWithAuth = axios.create({
  baseURL: BASE_URL,
  headers: baseHeaders,
});

axiosWithAuth.defaults.baseURL = BASE_URL;

axiosWithAuth.defaults.timeout = 7500;

axiosWithAuth.interceptors.request.use(
  async (config: any) => {
    if (!config) throw new Error('요청이 유실되었습니다.');

    let accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');

    if (accessToken == null) {
      return window.location.replace('/signIn');
    }

    const accessExpiredTime = getExpiredTimeFromToken(accessToken);
    const currentTime = new Date();

    if (accessExpiredTime < currentTime) {
      console.log('AccessToken has been expired and refreshed.');

      if (refreshToken != null) {
        const { data } = await getRefreshedToken(refreshToken);
        localStorage.setItem('accessToken', data.access_token);
        config.headers['Authorization'] = `Bearer ${data.access_token}`;
      }
    } else {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export { axiosWithAuth };
