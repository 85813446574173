import { Buffer } from 'buffer';

export const getExpiredTimeFromToken = (token: string) => {
  const payload = getDecodedToken(token);
  return new Date(payload.exp * 1000);
};

export const getDecodedToken = (token: string) => {
  const base64Payload = token.split('.')[1];
  const payload = Buffer.from(base64Payload, 'base64');
  return JSON.parse(payload.toString());
};
