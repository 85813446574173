import React, { FunctionComponent } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { firebaseAuth } from '@/firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { Header } from '@/components/Header';

interface OwnProps {}

type Props = OwnProps;

type FindPasswordFormData = {
  email: string;
};

const FindPassword: FunctionComponent<Props> = (props) => {
  const navigate = useNavigate();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FindPasswordFormData>();

  const onSubmit: SubmitHandler<FindPasswordFormData> = async (formData) => {
    try {
      await sendPasswordResetEmail(firebaseAuth, formData.email);
      alert(
        '비밀번호 재설정 이메일을 보냈습니다.\n이메일 안내에 따라 비밀번호를 재설정 해 주세요.'
      );
      navigate('/signIn');
    } catch (err: any) {
      console.error(err.message);
      alert('비밀번호 재설정 이메일 보내기를 실패하였습니다.');
    }
  };

  return (
    <div className={'dark'}>
      <div className="min-h-[100svh] flex flex-col flex-auto flex-shrink-0 antialiased bg-white dark:bg-gray-700 text-black dark:text-white">
        <Header />
        <div className="flex items-center sm:items-start">
          <div className="container mx-auto my-52 flex flex-col items-center">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col items-center">
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <div className="flex flex-col mt-2">
                      <label htmlFor="email" className="hidden">
                        Email
                      </label>
                      <input
                        type="email"
                        id="email"
                        placeholder="이메일을 입력해 주세요."
                        className="w-[300px] mt-2 py-3 px-3 rounded-lg bg-white dark:bg-gray-800
                      disabled:text-gray-500
                      border border-gray-400 dark:border-gray-700
                      text-gray-800 dark:text-gray-50 font-semibold
                      focus:border-blue-500 focus:outline-none"
                        {...register('email', {
                          required: {
                            value: true,
                            message: '이메일을 입력해 주세요.',
                          },
                          pattern: {
                            value:
                              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: '이메일 형식에 맞게 입력해 주세요.',
                          },
                        })}
                        {...field}
                      />
                    </div>
                  )}
                />
                {errors.email ? (
                  <p className="text-xs text-red-400 mt-1 ml-2 h-2">
                    {errors.email.message}
                  </p>
                ) : (
                  <div className="mt-1 ml-2 h-2"></div>
                )}
                <button
                  type="submit"
                  className="w-[300px] mt-8 sm:mt-4 bg-blue-600 dark:bg-gray-100 text-white dark:text-gray-800 font-bold py-3 px-6 rounded-lg hover:bg-blue-500 dark:hover:bg-gray-200 transition ease-in-out duration-300">
                  비밀번호 재설정 하기
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindPassword;
