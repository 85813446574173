import { atom } from 'recoil';
import { ComsUser } from '@/models/comsUser';

interface AuthAtomType {
  isLoading: boolean;
  isSignedIn: boolean;
  userId: string;
  email: string;
  name: string;
  nameFromOauth: string;
  phoneFromOauth: string;
  role: string;
  user: ComsUser | null;
}

export const authAtom = atom<AuthAtomType>({
  key: 'auth',
  default: {
    isLoading: true,
    isSignedIn: false,
    userId: '',
    email: '',
    name: '',
    nameFromOauth: '',
    phoneFromOauth: '',
    role: '',
    user: null,
  },
});
