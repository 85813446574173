import React, { FunctionComponent, useEffect } from 'react';
import { authAtom } from '@/atoms/auth';
import { useRecoilState } from 'recoil';
import { Authority, ComsUserStatus } from '@/constants';
import { useNavigate } from 'react-router-dom';
import { Header } from '@/components/Header';
import { SocialLogin } from '@/components/SocialLogin';

interface OwnProps {}

type Props = OwnProps;

const Unauthenticated: FunctionComponent<Props> = (props) => {
  const navigate = useNavigate();
  const [auth, setAuth] = useRecoilState(authAtom);

  useEffect(() => {
    const isAdmin = auth.user?.appAuthorities
      .map((appAuthority) => appAuthority.authority)
      .includes(Authority.ADMIN);
    if (auth.user?.comsUserStatus === ComsUserStatus.INITIAL && isAdmin) {
      navigate('/signUp');
    } else if (auth.user?.comsUserStatus === ComsUserStatus.ACTIVE && isAdmin) {
      navigate('/');
    }
  }, [auth]);

  const renderReason = () => {
    const isAdmin = auth.user?.appAuthorities
      .map((appAuthority) => appAuthority.authority)
      .includes(Authority.ADMIN);
    if (auth.user?.comsUserStatus === ComsUserStatus.HOLDING && isAdmin) {
      return '가입 승인 대기중입니다.\n만약 24시간이 지나도 승인이 되지 않으면\n관리자에게 문의해 주세요.';
    }
    return '접근 권한이 없습니다.\n관리자에게 문의해 주세요.';
  };

  return (
    <div className={'dark'}>
      <div
        className="min-h-[100svh] flex flex-col flex-auto flex-shrink-0 antialiased
      bg-white dark:bg-gray-700 text-black dark:text-white">
        <Header />
        <div className="flex flex-col justify-center min-h-[100svh]">
          <div className="text-center text-2xl font-bold whitespace-pre-line leading-[1.75]">
            {renderReason()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Unauthenticated;
