import React, { FunctionComponent, useEffect } from 'react';
// import { useNavigate, useSearchParams } from 'react-router-dom';
import { BASE_URL } from '@/config';
import axios from 'axios';
import { signInWithCustomToken } from 'firebase/auth';
import { firebaseAuth } from '@/firebase';
import { Authority } from '@/constants';

interface OwnProps {}

type Props = OwnProps;

const Naver: FunctionComponent<Props> = (props) => {
  // const [searchParams] = useSearchParams();
  // const code = searchParams.get('code');
  // const state = searchParams.get('state');
  // const accessToken = searchParams.get('access_token');

  useEffect(() => {
    // console.log(code);
    // console.log(state);

    /*axios
      .get(`/user/naver/oauth?code=${code}&state=${state}`, {
        baseURL: BASE_URL,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then((response) => {
        console.log(response.data);
        const token = response.data;
        signInWithCustomToken(firebaseAuth, token)
          .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            console.log(user);
            window.opener.afterNaverLogin(user);
            window.close();
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            alert(errorMessage);
          });
      });*/

    const accessToken = window.location.href.split('=')[1].split('&')[0];
    console.log(accessToken);

    axios
      .post(
        `/user/naver/oauth`,
        { accessToken, authority: Authority.ADMIN },
        {
          baseURL: BASE_URL,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      )
      .then((response) => {
        const { customToken, comsUser } = response.data;
        window.opener.afterNaverLogin(customToken, comsUser);
        window.close();

        /*signInWithCustomToken(firebaseAuth, token)
          .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            console.log(user);
            window.opener.afterNaverLogin(user);
            window.close();
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            alert(errorMessage);
          });*/
      });
  }, []);

  return <div></div>;
};

export default Naver;
