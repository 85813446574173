import React, { Suspense, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SignIn from '@/pages/SignIn';
import PrivateRoute from '@/components/PrivateRoute';
import SignUp from '@/pages/SignUp';
import { authAtom } from '@/atoms/auth';
import { useRecoilState } from 'recoil';
import { firebaseAuth } from '@/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import Kakao from '@/pages/Kakao';
import Naver from '@/pages/Naver';
import SignUpLanding from '@/pages/SignUpLanding';
import { getMe } from '@/apis/auth';
import Unauthenticated from '@/pages/Unauthenticated';
import { Authority, ComsUserStatus } from '@/constants';
import { AppAuthority } from '@/models/AppAuthority';
import FindPassword from '@/pages/FindPassword';

const Main = React.lazy(() => import('@/pages/Main'));

function App() {
  const [auth, setAuth] = useRecoilState(authAtom);

  useEffect(() => {
    return onAuthStateChanged(firebaseAuth, (firebaseUser: any) => {
      console.log('onAuthStateChanged');
      if (firebaseUser) {
        console.log('firebaseUser', firebaseUser);
        const accessToken = firebaseUser.accessToken;
        const refreshToken = firebaseUser.refreshToken;
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);

        getMe(accessToken).then((response) => {
          const isActive = response.comsUserStatus === ComsUserStatus.ACTIVE;
          const isAdmin = response.appAuthorities
            .map((appAuthority: AppAuthority) => appAuthority.authority)
            .includes(Authority.ADMIN);
          setAuth({
            ...auth,
            isSignedIn: isActive && isAdmin,
            isLoading: false,
            userId: firebaseUser.uid,
            email: firebaseUser.email,
            user: response,
          });
        });
      } else {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        setAuth({
          ...auth,
          isLoading: false,
          isSignedIn: false,
          userId: '',
          email: '',
          user: null,
        });
      }
    });
  }, []);

  return auth.isLoading ? (
    <div>Loading</div>
  ) : (
    <Router>
      <Suspense
        fallback={
          <div className="w-[100vh] min-h-[100svh] flex justify-center items-center">
            center
          </div>
        }>
        <Routes>
          <Route path="/unauthenticated" element={<Unauthenticated />} />
          <Route path="/signUp" element={<SignUp />} />
          <Route path="/signUpLanding" element={<SignUpLanding />} />
          <Route path="/findPassword" element={<FindPassword />} />
          <Route path="/signIn" element={<SignIn />} />
          <Route path="/kakao" element={<Kakao />} />
          <Route path="/naver" element={<Naver />} />
          <Route path="/*" element={<PrivateRoute element={<Main />} />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
