import { authHeader, axiosWithAuth } from '@/apis/base';
import axios from 'axios';

export const getMe = async (token: string | null | undefined) => {
  const { data } = await axiosWithAuth.get(`/user/me`, {
    headers: authHeader(token),
  });
  return data;
};

export const getRefreshedToken = async (refreshToken: string) => {
  return axios.post(
    `https://securetoken.googleapis.com/v1/token?key=AIzaSyAw3bRNPk4s4aP8xfoAOBxVa3m6PqydM6Y`,
    {
      grant_type: 'refresh_token',
      refresh_token: `${refreshToken}`,
    }
  );
};
