import { useEffect, useRef } from 'react';
import { NAVER_CLIENT_ID, NAVER_REDIRECT_URI } from '@/config';

interface NaverLoginInstance {
  init: () => void;
  logout: () => void;
}

declare global {
  interface Window {
    naver: any;
  }
}

export class NaverLoginManager {
  private static instance: NaverLoginInstance | null = null;

  static getInstance(): NaverLoginInstance | null {
    return NaverLoginManager.instance;
  }

  static setInstance(instance: NaverLoginInstance) {
    NaverLoginManager.instance = instance;
  }
}

export const useNaverLogin = () => {
  const initialized = useRef(false);
  const naverRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!initialized.current && naverRef.current) {
      const naver = window.naver;

      const naverLogin = new naver.LoginWithNaverId({
        clientId: NAVER_CLIENT_ID,
        callbackUrl: NAVER_REDIRECT_URI,
        callbackHandle: true,
        isPopup: true,
        loginButton: {
          color: 'black',
          type: 1,
          height: 25,
        },
      });

      naverLogin.init();
      NaverLoginManager.setInstance(naverLogin);
      initialized.current = true;
    }
  }, []);

  const naverLogin = () => {
    if (naverRef.current?.children[0]) {
      (naverRef.current.children[0] as HTMLElement).click();
    }
  };

  const naverLogout = () => {
    const instance = NaverLoginManager.getInstance();
    if (instance) {
      instance.logout();
    }
  };

  return {
    naverRef,
    naverLogin,
    naverLogout,
  };
};
