import React, { FunctionComponent, useEffect, useState } from 'react';
import { BASE_URL } from '@/config';
import axios from 'axios';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { Button } from '@mui/material';
import { authAtom } from '@/atoms/auth';
import { useRecoilState } from 'recoil';
import { useLocation, useNavigate } from 'react-router-dom';
import { Authority, ComsUserStatus, OAuthType } from '@/constants';
import { Header } from '@/components/Header';
import { editComsUser } from '@/apis/coms-user';
import { ComsUserForm } from '@/pages/user/UserDetail';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { firebaseAuth } from '@/firebase';

interface OwnProps {}

type Props = OwnProps;

type SignUpFormData = {
  uid: string;
  email: string;
  password: string;
  passwordConfirm: string;
  name: string;
  phone: string;
};

interface LocationState {
  uid: string;
  email: string;
  nameFromOauth: string;
  phoneFromOauth: string;
}

const SignUp: FunctionComponent<Props> = (props) => {
  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm<SignUpFormData>();
  const [auth, setAuth] = useRecoilState(authAtom);
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as LocationState;
  const [isOauth, setIsOauth] = useState<boolean>(false);

  useEffect(() => {
    if (state?.email != null) {
      setIsOauth(true);
      setValue('email', state.email);
    }
    if (state?.nameFromOauth != null) {
      setValue('name', state.nameFromOauth);
    }
    if (state?.phoneFromOauth != null) {
      setValue('phone', state.phoneFromOauth.replaceAll('-', ''));
    }
  }, [state]);

  const signInWithComsServer = (token: any, oauthType: OAuthType | null) => {
    return axios.post(
      `/user/firebase/oauth`,
      { oauthType, authority: Authority.ADMIN },
      {
        baseURL: BASE_URL,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const onSubmit: SubmitHandler<SignUpFormData> = async (formData) => {
    if (isOauth) {
      const comsUserForm: ComsUserForm = {
        uid: state.uid,
        comsUserStatus: ComsUserStatus.HOLDING,
        name: formData.name,
        phone: formData.phone,
      };
      const { data: comsUser } = await editComsUser(comsUserForm);
      setAuth({ ...auth, user: comsUser });
      navigate('/');
    } else {
      createUserWithEmailAndPassword(
        firebaseAuth,
        formData.email,
        formData.password
      )
        .then((userCredential: any) => {
          // Signed in
          const user = userCredential.user;
          console.log(userCredential);
          signInWithComsServer(user.accessToken, OAuthType.EMAIL)
            .then((response) => {
              setAuth({ ...auth, user: response.data });
              navigate('/');
            })
            .catch((error: any) => {
              const { errorCode, message: errorMessage } = error.response.data;
              console.log(error);
            });
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          if (errorCode === 'auth/email-already-in-use') {
            alert('이미 가입된 이메일입니다.');
            reset({
              password: '',
              passwordConfirm: '',
            });
          } else {
            alert(errorMessage);
          }
        });
    }
  };

  return (
    <div className={'dark'}>
      <div className="min-h-[100svh] flex flex-col flex-auto flex-shrink-0 antialiased bg-white dark:bg-gray-700 text-black dark:text-white">
        <Header />
        <div className="flex flex-col mx-auto mt-24 w-[400px]">
          <div className="text-center text-2xl font-bold mb-4">회원 가입</div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col">
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <div className="flex flex-col mt-2">
                    <label htmlFor="email" className="hidden">
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      placeholder="이메일"
                      disabled={isOauth}
                      className="w-100 mt-2 py-3 px-3 rounded-lg bg-white dark:bg-gray-800
                      disabled:text-gray-500
                      border border-gray-400 dark:border-gray-700
                      text-gray-800 dark:text-gray-50 font-semibold
                      focus:border-blue-500 focus:outline-none"
                      {...register('email', {
                        required: {
                          value: true,
                          message: '이메일을 입력해 주세요.',
                        },
                        pattern: {
                          value:
                            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: '이메일 형식에 맞게 입력해 주세요.',
                        },
                      })}
                      {...field}
                    />
                  </div>
                )}
              />
              {errors.email ? (
                <p className="text-xs text-red-400 mt-1 ml-2 h-2">
                  {errors.email.message}
                </p>
              ) : (
                <div className="mt-1 ml-2 h-2"></div>
              )}
              {!isOauth && (
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <div className="flex flex-col mt-2">
                      <label htmlFor="password" className="hidden">
                        비밀번호
                      </label>
                      <input
                        type="password"
                        id="password"
                        placeholder="비밀번호"
                        className="w-100 mt-2 py-3 px-3 rounded-lg bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-700 text-gray-800 dark:text-gray-50 font-semibold focus:border-blue-500 focus:outline-none"
                        {...register('password', {
                          required: {
                            value: true,
                            message: '비밀번호를 입력해 주세요.',
                          },
                          minLength: {
                            value: 8,
                            message: '최소 8자 이상 입력해 주세요.',
                          },
                        })}
                        {...field}
                      />
                    </div>
                  )}
                />
              )}
              {!isOauth && errors.password ? (
                <p className="text-xs text-red-400 mt-1 ml-2 h-2">
                  {errors.password.message}
                </p>
              ) : (
                <div className={`${!isOauth && 'mt-1 ml-2 h-2'}`}></div>
              )}
              {!isOauth && (
                <Controller
                  name="passwordConfirm"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <div className="flex flex-col mt-2">
                      <label htmlFor="passwordConfirm" className="hidden">
                        비밀번호
                      </label>
                      <input
                        type="password"
                        id="passwordConfirm"
                        placeholder="비밀번호 확인"
                        className="w-100 mt-2 py-3 px-3 rounded-lg bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-700 text-gray-800 dark:text-gray-50 font-semibold focus:border-blue-500 focus:outline-none"
                        {...register('passwordConfirm', {
                          required: {
                            value: true,
                            message: '비밀번호 확인을 입력해 주세요.',
                          },
                          validate: {
                            matchPassword: (value) =>
                              value === watch('password'),
                          },
                        })}
                        {...field}
                      />
                    </div>
                  )}
                />
              )}
              {!isOauth && errors.passwordConfirm?.type === 'matchPassword' ? (
                <p className="text-xs text-red-400 mt-1 ml-2 h-2">
                  비밀번호가 다릅니다.
                </p>
              ) : !isOauth && errors.passwordConfirm ? (
                <p className="text-xs text-red-400 mt-1 ml-2 h-2">
                  {errors.passwordConfirm.message}
                </p>
              ) : (
                <div className={`${!isOauth && 'mt-1 ml-2 h-2'}`}></div>
              )}
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <div className="flex flex-col mt-2">
                    <label htmlFor="name" className="hidden">
                      이름
                    </label>
                    <input
                      type="text"
                      id="name"
                      placeholder="이름"
                      className="w-100 mt-2 py-3 px-3 rounded-lg bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-700 text-gray-800 dark:text-gray-50 font-semibold focus:border-blue-500 focus:outline-none"
                      {...register('name', {
                        required: {
                          value: true,
                          message: '이름을 입력해 주세요.',
                        },
                      })}
                      {...field}
                    />
                  </div>
                )}
              />
              {errors.name ? (
                <p className="text-xs text-red-400 mt-1 ml-2 h-2">
                  {errors.name.message}
                </p>
              ) : (
                <div className="mt-1 ml-2 h-2"></div>
              )}
              <Controller
                name="phone"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <div className="flex flex-col mt-2">
                    <label htmlFor="phone" className="hidden">
                      연락처
                    </label>
                    <input
                      type="text"
                      id="phone"
                      placeholder="연락처"
                      className="w-100 mt-2 py-3 px-3 rounded-lg bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-700 text-gray-800 dark:text-gray-50 font-semibold focus:border-blue-500 focus:outline-none"
                      {...register('phone', {
                        required: {
                          value: true,
                          message: '연락처를 입력해 주세요.',
                        },
                      })}
                      {...field}
                    />
                  </div>
                )}
              />
              {errors.phone ? (
                <p className="text-xs text-red-400 mt-1 ml-2 h-2">
                  {errors.phone.message}
                </p>
              ) : (
                <div className="mt-1 ml-2 h-2"></div>
              )}

              <div className="mt-6">
                <Button
                  variant="contained"
                  type="submit"
                  className="w-full h-[48px]">
                  <span className="font-bold text-lg">가입 하기</span>
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
