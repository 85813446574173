import React, { FunctionComponent, useEffect } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { firebaseAuth } from '@/firebase';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { BASE_URL } from '@/config';
import { authAtom } from '@/atoms/auth';
import { useRecoilState } from 'recoil';
import axios from 'axios';
import { Authority, ComsUserStatus, OAuthType } from '@/constants';
import { SocialLogin } from '@/components/SocialLogin';

interface OwnProps {}

type Props = OwnProps;

type SignInFormData = {
  email: string;
  password: string;
};

const signIn: FunctionComponent<Props> = (props) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInFormData>();
  const navigate = useNavigate();
  const [auth, setAuth] = useRecoilState(authAtom);

  useEffect(() => {
    const isAdmin = auth.user?.appAuthorities
      .map((appAuthority) => appAuthority.authority)
      .includes(Authority.ADMIN);
    if (auth.isSignedIn) {
      navigate('/');
    } else if (
      auth.user?.comsUserStatus === ComsUserStatus.INITIAL &&
      isAdmin
    ) {
      navigate('/signUp', {
        state: {
          uid: auth.user?.uid,
          email: auth.user?.email,
          nameFromOauth: auth.user?.nameFromOauth,
          phoneFromOauth: auth.user?.phoneFromOauth,
        },
      });
    } else if (auth.user != null) {
      navigate('/unauthenticated');
    }
  }, [auth]);

  const onSubmit: SubmitHandler<SignInFormData> = (formData) => {
    console.log(formData);
    signInWithEmail(formData);
  };

  const signInWithEmail = (formData: SignInFormData) => {
    signInWithEmailAndPassword(firebaseAuth, formData.email, formData.password)
      .then((userCredential: any) => {
        // Signed in
        const user = userCredential.user;
        signInWithComsServer(user.accessToken, OAuthType.EMAIL, Authority.ADMIN)
          .then((response) => {
            console.log('signInWithComsServer success');
          })
          .catch((error: any) => {
            const { errorCode, message: errorMessage } = error.response.data;
            console.log(errorMessage);
          });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode);
        console.log(errorMessage);
        if (errorCode === 'auth/user-not-found') {
          alert('유저 정보가 없습니다.');
        }
        if (errorCode === 'auth/wrong-password') {
          alert('비밀번호가 틀렸습니다.');
        }
      });
  };

  const signInWithComsServer = (
    token: any,
    oauthType: OAuthType | null,
    authority: string
  ) => {
    return axios.post(
      `/user/firebase/oauth`,
      { oauthType, authority },
      {
        baseURL: BASE_URL,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  return (
    <div className="relative min-h-[100svh] flex items-center justify-center">
      <div className="bg-green-900 absolute top-0 left-0 bg-gradient-to-b from-gray-900 via-gray-900 to-green-800 bottom-0 leading-5 w-full overflow-hidden">
        <svg
          className="relative block "
          style={{ width: 'calc(100% + 10px)' }}
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none">
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            fill="#fff"></path>
        </svg>
      </div>
      <div className="relative sm:flex sm:flex-row justify-center bg-transparent sm:p-24 rounded-3xl">
        <div className="flex-col flex  self-center lg:p-14 sm:max-w-4xl xl:max-w-md  z-10">
          <div className="self-start hidden lg:flex flex-col  text-gray-300">
            <h1 className="my-3 font-semibold text-4xl">청광식품</h1>
            <p className="pr-3 text-sm opacity-75">주문 관리 시스템</p>
          </div>
        </div>
        <div className="flex justify-center self-center  z-10">
          <div className="p-12 bg-gradient-to-b from-gray-900 via-gray-900 to-green-800 mx-auto rounded-3xl w-96 ">
            <div className="mb-7">
              <h3 className="font-semibold text-2xl text-gray-300">로그인</h3>
              <p className="text-gray-500">
                계정이 없으신가요?{' '}
                <Link
                  to="/signUpLanding"
                  className="text-sm text-green-700 hover:text-green-600">
                  회원가입
                </Link>
              </p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="space-y-6">
                <div className="relative">
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <input
                        className="w-full text-sm text-white px-4 py-3 bg-gray-900 border border-gray-700 rounded-lg focus:outline-none focus:border-green-400"
                        type=""
                        placeholder="이메일"
                        {...register('email', {
                          required: {
                            value: true,
                            message: '이메일을 입력해 주세요.',
                          },
                          pattern: {
                            value:
                              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: '이메일 형식에 맞게 입력해 주세요.',
                          },
                        })}
                        {...field}
                      />
                    )}
                  />
                  <div className="absolute top-[48px] left-[12px] text-sm text-red-400">
                    {errors.email?.type === 'required' && (
                      <p>{errors.email.message}</p>
                    )}
                    {errors.email?.type === 'pattern' && (
                      <p>{errors.email.message}</p>
                    )}
                  </div>
                </div>

                <div className="relative">
                  <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <input
                        placeholder="비밀번호"
                        type="password"
                        className="text-sm text-gray-200 px-4 py-3 rounded-lg w-full bg-gray-900 border border-gray-700 focus:outline-none focus:border-green-400"
                        {...register('password', {
                          required: {
                            value: true,
                            message: '비밀번호를 입력해 주세요.',
                          },
                          minLength: {
                            value: 8,
                            message: '최소 8자 이상 입력해 주세요.',
                          },
                        })}
                        {...field}
                      />
                    )}
                  />

                  <div className="absolute top-[48px] left-[12px] text-sm text-red-400">
                    {errors.password?.type === 'required' && (
                      <p>{errors.password.message}</p>
                    )}
                    {errors.password?.type === 'minLength' && (
                      <p>{errors.password.message}</p>
                    )}
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="text-sm ml-auto">
                    <Link
                      to="/findPassword"
                      className="text-green-700 hover:text-green-600">
                      비밀번호를 잊어버리셨나요?
                    </Link>
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="w-full flex justify-center bg-green-800  hover:bg-green-700 text-gray-100 p-3  rounded-lg tracking-wide font-semibold  cursor-pointer transition ease-in duration-500">
                    로그인
                  </button>
                </div>
                <div className="flex items-center justify-center space-x-2 my-5">
                  <span className="h-px w-16 bg-gray-700"></span>
                  <span className="text-gray-400 font-normal">or</span>
                  <span className="h-px w-16 bg-gray-700"></span>
                </div>
                <SocialLogin isTwoRow={true} />
              </div>
            </form>
            {/*<div className="mt-7 text-center text-gray-400 text-xs">
              <button
                onClick={() => {
                  Kakao.Auth.logout(() => {
                    console.log('logout');
                  });
                }}>
                카카오 로그아웃
              </button>
              <span>
                Copyright © 2021-2023
                <a
                  href="https://codepen.io/uidesignhub"
                  rel=""
                  target="_blank"
                  title="Codepen aji"
                  className="text-violet-500 hover:text-violet-600 ">
                  AJI
                </a>
              </span>
            </div>*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default signIn;
