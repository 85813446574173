import React, { FunctionComponent } from 'react';
import { SocialLogin } from '@/components/SocialLogin';
import { Header } from '@/components/Header';
import { useNavigate } from 'react-router-dom';

interface OwnProps {}

type Props = OwnProps;

const SignUpLanding: FunctionComponent<Props> = (props) => {
  const navigate = useNavigate();

  return (
    <div className={'dark'}>
      <div className="min-h-[100svh] flex flex-col flex-auto flex-shrink-0 antialiased bg-white dark:bg-gray-700 text-black dark:text-white">
        <Header />
        <div className="flex items-center sm:items-start">
          <div className="container mx-auto mt-52 flex flex-col items-center">
            <button
              type="button"
              onClick={() => navigate('/signUp')}
              className="md:w-52 bg-blue-600 dark:bg-gray-100 text-white dark:text-gray-800 font-bold py-3 px-6 rounded-lg mt-4 hover:bg-blue-500 dark:hover:bg-gray-200 transition ease-in-out duration-300">
              이메일로 가입하기
            </button>
            <div className="mt-12">
              <SocialLogin />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpLanding;
